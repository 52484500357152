// socketService.js
import io from 'socket.io-client';

import i18n from 'i18next';
import './core/translations/i18n';

//const socket = io('https://prod-dev-knhk.1.us-1.fl0.io/');
const socket = io('https://backend.antisemitescore.org/')
const SocketService = {
  init() {
    socket.on('connect', () => {
      console.log('Conectado al servidor');
    });

    socket.on('disconnect', () => {
      console.log('Desconectado del servidor');
    });
  },

  subscribeToProgress(updateFunc, t) {
    socket.on('progress', message => {
      console.log('Progreso:', message);
      const translatedMessage = i18n.t(message.split('|')[0]).trimEnd();
      console.log(translatedMessage);
      updateFunc(translatedMessage);
    });
  },

  unsubscribeFromProgress() {
    socket.off('progress');
  },

  emitRegistration(idUnico) {
    socket.emit('register', idUnico);
  },

  // ... otros métodos para manejar eventos específicos ...
};

export default SocketService;
