import {
  Container,
  Heading,
  Text,
  Divider,
  VStack,
  Link,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import '../../../../core/translations/i18n';

function SobreNosotrosText() {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.100');

  return (
    <Container maxW="container.lg" p={4}>
      <VStack
        spacing={5}
        backgroundColor={bgColor}
        padding={5}
        borderRadius="lg"
        boxShadow="md"
      >
        <Heading as="h1" size="xl">
          {t('aboutus')}
        </Heading>
        <Divider />
        <Text color={textColor} fontSize="lg">
          {t('parrafo1')}
        </Text>
        <Text color={textColor}>{t('parrafo2')}</Text>
        <Text color={textColor}>{t('parrafo3')}</Text>
        <Text color={textColor}>
          {t('parrafo4')}
          <Link color="blue.500" href="https://twitter.com/antisemitescore">
            @antisemitescore
          </Link>
          {t('parrafo4b')}
        </Text>
        <Text color={textColor}>{t('parrafo5')}</Text>
        <Text color={textColor}>{t('parrafo6')}</Text>
      </VStack>
    </Container>
  );
}

export default SobreNosotrosText;
