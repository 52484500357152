import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from '@chakra-ui/react';
import ShareButton from './ShareButton';

function CustomCard({
  isScoreCard,
  isTweetCard,
  scoreLevel,
  comentario,
  duration,
  children,
  ...props
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isTweetPreview, setIsTweetPreview] = useState(isTweetCard);
  const [isScoreComment, setIsScoreComment] = useState(isScoreCard);
  useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, duration);
  }, []);

  return (
    <Box
      marginBottom="15px"
      width={
        children ? ['285px', '336px', '436px'] : ['385px', '436px', '536px']
      }
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      boxShadow="lg" // Aplicando sombra
      p={4}
      {...props} // Pasando cualquier prop adicional
    >
      <Box m={5}>
        {children ? (
          children
        ) : (
          <>
            {comentario && (
              <Accordion allowToggle index={isOpen ? 0 : -1}>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {scoreLevel && !isNaN(scoreLevel) ? (
                        <Box
                          fontWeight="semibold"
                          as="h4"
                          lineHeight="tight"
                          isTruncated
                        >
                          Antisemite score: {scoreLevel}
                        </Box>
                      ) : (
                        <Box
                          fontWeight="semibold"
                          as="h4"
                          lineHeight="tight"
                          isTruncated
                        >
                          Antisemite score: 0
                        </Box>
                      )}
                    </Box>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Box>{comentario}</Box>
                    <ShareButton
                      text={
                        'Score: ' +
                        scoreLevel +
                        ' @' +
                        localStorage.perfil +
                        ': ' +
                        (parseInt(scoreLevel) > 5 ? '🔴' : '🟠') +
                        ' ' +
                        comentario.slice(0, 220) +
                        '... Antisemitescore.org'
                      }
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default CustomCard;
