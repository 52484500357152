import { useTranslation } from 'react-i18next';
import './core/translations/i18n';

function ShareButton({ text }) {
  const { t } = useTranslation();
  const tweetText = encodeURIComponent(text);

  const twitterShareUrl = `https://twitter.com/intent/tweet?text=${tweetText}`;

  return (
    <a
      href={twitterShareUrl}
      target="_blank"
      rel="noopener noreferrer"
      style={{
        textAlign: 'center',
        display: 'block',
        width: '100%',
        marginTop: '15px',
        fontWeight: '700',
      }}
    >
      {t('share')}
    </a>
  );
}

export default ShareButton;
