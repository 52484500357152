module.exports = {
    daysOfWeek: [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo',
    ],
    getColor: (score) => {
      return score === '0' ? '#FECDD3' : '#BE123C';
    },
    formatNumber: (num) =>  {
        // Si el número es menor que 1000, lo devolvemos como está
        if (num < 1000) {
          return num.toString();
        }
        // Si el número es mayor o igual que 1000 pero menor que 1000000 (un millón)
        else if (num >= 1000 && num < 1000000) {
          // Dividimos por 1000 y redondeamos a un decimal
          return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' mil';
        }
        // Si el número es mayor o igual que un millón
        else {
          // Dividimos por 1.000.000 y redondeamos a un decimal
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' mill';
        }
      },
       shareContent:(perfil) =>{
        if (navigator.share) {
          navigator
            .share({
              title: document.title,
              text: 'Share score result!',
              url: 'https://' + window.location.host + '/' + perfil,
            })
            .then(() => console.log('Successful share'))
            .catch(error => {console.log('Error sharing:', error)
          });
        } else {
          // Fallback, en caso de que la API Web Share no esté soportada
          // Podrías copiar el enlace al portapapeles o mostrar algún diálogo/modal personalizado
          console.log('Web Share API not supported.');
        }
      }
  };
  