import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  components: {
    Tab: {
      // style for the unselected tab
      baseStyle: ({ isActive }) => ({
        _selected: {
          color: 'white',
          bg: 'blue.500',
        },
      }),
    },
  },
  fonts: {
    body: "'Poppins', sans-serif",
    heading: "'Poppins', sans-serif",
  },
});

export default theme;
