// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  
  .blinking-dots {
    display: inline-block;
    position: relative;
  }
  
  .blinking-dots span {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: black;
    position: absolute;
    animation: blink 1.5s infinite;
  }
  
  .blinking-dots span:nth-child(1) {
    left: 0;
    animation-delay: 0s;
  }
  
  .blinking-dots span:nth-child(2) {
    left: 10px;
    animation-delay: 0.2s;
  }
  
  .blinking-dots span:nth-child(3) {
    left: 20px;
    animation-delay: 0.4s;
  }
  `, "",{"version":3,"sources":["webpack://./src/style/animatedDots.css"],"names":[],"mappings":"AAAA;IACI;MACE,UAAU;IACZ;IACA;MACE,UAAU;IACZ;EACF;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,kBAAkB;IAClB,8BAA8B;EAChC;;EAEA;IACE,OAAO;IACP,mBAAmB;EACrB;;EAEA;IACE,UAAU;IACV,qBAAqB;EACvB;;EAEA;IACE,UAAU;IACV,qBAAqB;EACvB","sourcesContent":["@keyframes blink {\n    0%, 100% {\n      opacity: 0;\n    }\n    50% {\n      opacity: 1;\n    }\n  }\n  \n  .blinking-dots {\n    display: inline-block;\n    position: relative;\n  }\n  \n  .blinking-dots span {\n    display: inline-block;\n    width: 5px;\n    height: 5px;\n    border-radius: 50%;\n    background-color: black;\n    position: absolute;\n    animation: blink 1.5s infinite;\n  }\n  \n  .blinking-dots span:nth-child(1) {\n    left: 0;\n    animation-delay: 0s;\n  }\n  \n  .blinking-dots span:nth-child(2) {\n    left: 10px;\n    animation-delay: 0.2s;\n  }\n  \n  .blinking-dots span:nth-child(3) {\n    left: 20px;\n    animation-delay: 0.4s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
