import { Button } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'

import { useAuth0 } from "@auth0/auth0-react";
import { getConfig } from '@testing-library/react';

export default function TestButton() {
    const { apiOrigin = "http://localhost:3000", audience } = getConfig();
    const [token, setToken] = useState(null);
    const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    loginWithPopup
    } = useAuth0();
    const [state, setState] = useState({
        showResult: false,
        apiMessage: "",
        error: null,
      });
    

    useEffect(() => {
        callApi();
      }, [isAuthenticated]);

    const callApi = async () => {
        try {

          const token = await getAccessTokenSilently();
          console.log(token);
          setToken(token);
          const response = await fetch(`${apiOrigin}/api/external`, {
         
          });
    
          const responseData = await response.json();
    
          setState({
            ...state,
            showResult: true,
            apiMessage: responseData,
          });
        } catch (error) {
            console.log(error);
          setState({
            ...state,
            error: error.error,
          });
        }
      };

  return (
    !isAuthenticated ?  <Button
    id="qsLoginBtn"
    color="primary"
    className="btn-margin"
    onClick={loginWithPopup}
  >
   Login
  </Button> : <Button>{user.name}</Button>
  )
}
