
import axios from 'axios';

const API_BASE_URL_PROD = 'https://backend.antisemitescore.org/';
// const API_BASE_URL_STG = 'https://stg-backend.antisemitescore.org/';


const apiService = axios.create({
  baseURL: API_BASE_URL_PROD,
  // Other Axios configurations can go here
});

export default apiService;