import { VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { easeCubic } from 'd3-ease';
import CustomCard from './CustomCard';

const Logo = ({ targetScore, comment }) => {
  const [currentScore, setCurrentScore] = useState(0);

  const [comentario, setComentario] = useState('');
  const [duration, setDuration] = useState(
    targetScore !== '' ? 3000 * targetScore : 0
  );
  useEffect(() => {
    let animationFrameId;

    // console.log(duration); // Duración de la animación en milisegundos
    const startTime = Date.now();

    const animate = () => {
      const currentTime = Date.now();
      const elapsedTime = currentTime - startTime;
      const progress = Math.min(elapsedTime / duration, 1);
      const easedProgress = easeCubic(progress);
      const newScore =
        currentScore + (targetScore - currentScore) * easedProgress;
      setCurrentScore(newScore);
      setComentario(comment);

      if (progress < 1) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animate();
    // console.log(((currentScore - 1) / 9) * 180 - 90, currentScore, targetScore);
    return () => cancelAnimationFrame(animationFrameId);
  }, [targetScore, comment]);

  const rotation =
    ((isNaN(currentScore) ? 0 : currentScore - 1) / 9) * 180 - 90;

  return (
    <VStack >
      {/* <Box
        position="relative"
        width="180px"
        height="90px"
        bgImage={`url('${Background}')`}
        bgSize="cover"
        bgPosition="center"
      >
        <Image
          src="/needle.png"
          alt="Aguja"
          position="absolute"
          bottom="47%"
          left="50%"
          transform={`translate(-50%, 50%) rotate(${rotation}deg)`}
          transformOrigin="50% 100%"
          height="100px"
        />
      </Box> */}
      {comentario && (
        <CustomCard
          scoreLevel={currentScore.toFixed(1)}
          comentario={comentario}
          duration={duration}
        />
      )}
    </VStack>
  );
};

export default Logo;
