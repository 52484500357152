import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from '@chakra-ui/react';
import SobreNosotrosText from './SobreNosotrosText';

const ModalAboutUs = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Box maxHeight="md" overflowY="auto" className="div-scroll">
            <SobreNosotrosText />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            color="white"
            backgroundColor="black"
            borderRadius="0"
            _hover={{ color: 'black', background: 'gray' }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalAboutUs;
