import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  InputRightElement,
  Input,
  InputGroup,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';


const ModalApiToken = ({ isOpen, onClose }) => {
    const [token, setToken] = useState('');
    const {
        isAuthenticated,
        getAccessTokenSilently,
        } = useAuth0();

    useEffect(() => {
        callApi();
      }, [isAuthenticated]);

    const callApi = async () => {
        try {
          const token = await getAccessTokenSilently();
          setToken(token);
        } catch (error) {
         
        }
      };

      const handleCopy = () =>{
        navigator.clipboard.writeText(token)
      }
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        {isAuthenticated ?
        <ModalBody>

          <Box maxHeight="md" overflowY="auto" className="div-scroll">
            <p>Para utilizar la API de antisemite score necesitas el siguiente token:</p>
            <br/>
            <InputGroup size='md'>
                <Input
                    pr='4.5rem'
                    defaultValue={token}
                    readOnly={true}
                />
                <InputRightElement width='4.5rem'>
                <Button h='1.75rem' size='sm' onClick={handleCopy}>
                Copiar
                </Button>
      </InputRightElement>
    </InputGroup>
          </Box>
        </ModalBody>:
        <ModalBody maxHeight="md" overflowY="auto" className="div-scroll">
                <p>Debes estar registrado para acceder a esta sección.</p>
        </ModalBody>
                 }

        <ModalFooter>
          <Button
            color="white"
            backgroundColor="black"
            borderRadius="0"
            _hover={{ color: 'black', background: 'gray' }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalApiToken;
