// i18n.js

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        botonAnalizar: 'Analyze',
        placeholderPerfil: 'Twitter profile URL / @user...',
        history: 'Search History',
        topSearched: 'Top Searched',
        relatedTweets: 'Related Tweets',
        score: 'Antisemite score:',
        noScore: 'No score available',
        toast1: 'You must provide a twitter account or username',
        toast2: 'we have not found any publications realted to the event',
        toast3:
          'Currently no publications talk about the attack or the conflict',
        toast4: 'User does not exist',
        toast5: 'Something went wrong ',
        toast6: 'Unknown error. Try later.',
        nothing: 'Nothing here...',
        step1:
          'Enter a Twitter account, and our technology will read through all the posts and assign an antisemitism score.',
        eliminarhistorial: 'Are you sure you want to delete this user?',
        explicacion:
          'Our technology offers an advanced Twitter account monitoring service, conducting a thorough analysis of posts. By focusing on tweets related to the Israeli-Palestinian conflict, along with a sophisticated algorithm, we determine if the account expresses antisemitic content and to what degree.',
        explicacion2:
          'The audited accounts will be posted along with their results on our Twitter account: @antisemitescore',
        share: 'Share on Twitter',
        parrafo1:
          'AntisemiteScore.org represents a collective effort to confront antisemitism in the digital realm. Our diverse team, composed of developers from various nationalities, ideologies, and backgrounds, unites under the shared conviction of promoting tolerance and human rights.',
        parrafo2:
          'Our mission is to identify and challenge antisemitic discourse, particularly on social media. To achieve this, we have developed advanced monitoring technology that thoroughly analyzes Twitter accounts. We focus on tweets related to the Israeli-Palestinian conflict, employing a sophisticated algorithm that assesses whether an account expresses antisemitic content and, if so, determines the extent of such expression.',
        parrafo3:
          'This approach allows us to highlight not only explicitly offensive comments but also those that may be subtle or less obvious, contributing to a more complete understanding of antisemitism in social media.',
        parrafo4:
          'We publish the results of our audits on our Twitter account, @antisemitescore, where audited accounts are displayed along with their respective results. This practice underscores our commitment to transparency and offers a valuable tool for those interested in online discourse dynamics.',
        parrafo5:
          'At AntisemiteScore.org, we strive to create a world where respect and mutual understanding outweigh hate and intolerance. Through our platform, we seek to raise awareness of antisemitism and empower users to recognize and confront hate speech.',
        parrafo6:
          'Our project is not just a technological solution; it is a call to action for positive change in the way we communicate and understand cultural and personal differences.',
        disclaimer: 'Disclaimer',
        aboutus: 'About Us',
        howitworks: 'How it Works',
        iomessage: 'Starting',
        iomessage2: 'Fetching & Saving',
        iomessage3: 'Filtering related content',
        iomessage4: 'Cleaning text',
        iomessage5: 'Processing',
        iomessage6: 'Posting on @antisemitescore',
        suspendido:
          "Our account on X (formerly Twitter) has been temporarily suspended, possibly due to a misunderstanding with the platform's policies. At https://antisemitescore.org, we continue our commitment to raising awareness about anti-Semitism and combating hate speech. We are in communication with X to clarify and resolve this situation, ensuring that our activities are aligned with their guidelines. Our mission persists through other channels as we work to resume our activity on X. We value your support and understanding during this time.",
      },
    },
    es: {
      translation: {
        botonAnalizar: 'Analizar',
        placeholderPerfil: 'URL del perfil de Twitter / @usuario...',
        history: 'Historial',
        topSearched: 'Más buscado',
        relatedTweets: 'Tweets Relacionados',
        score: 'Puntuación de Antisemitismo:',
        noScore: 'Puntuación no disponible',
        toast1: 'Debes proporcionar una cuenta de Twitter o nombre de usuario',
        toast2:
          'No hemos encontrado ninguna publicación relacionada con el evento',
        toast3:
          'Actualmente no hay publicaciones que hablen sobre el ataque o el conflicto',
        toast4: 'El usuario no existe',
        toast5: 'Algo salió mal',
        toast6: 'Error desconocido. Prueba más tarde.',
        nothing: 'Nada por aqui...',
        step1:
          'Ingrese una cuenta de twitter y nuestra tecnologia leera todo lo escrito y otorgara un score de antisemitismo',
        eliminarhistorial:
          '¿Estás seguro de que deseas eliminar este usuario? ',
        explicacion:
          'Nuestra tecnología ofrece un servicio avanzado de monitoreo de cuentas de Twitter, realizando un análisis exhaustivo de las publicaciones. Al enfocarse en los tweets relacionados al conflicto israel palestina, junto con un algoritmo sofisticado, determinamos si la cuenta expresa un contenido antisemita y en que grado.',
        explicacion2:
          'Las cuentas auditadas seran posteadas junto con sus resultados en nuestra cuenta de twitter: @antisemitescore',
        share: 'Comparte en Twitter',
        parrafo1:
          'AntisemiteScore.org representa un esfuerzo colectivo para enfrentar el antisemitismo en el ámbito digital. Nuestro equipo diverso, integrado por desarrolladores de distintas nacionalidades, ideologías y orígenes, se une bajo la convicción común de promover la tolerancia y los derechos humanos.',
        parrafo2:
          'Nuestra misión es identificar y desafiar el discurso antisemita, particularmente en las redes sociales. Para lograr esto, hemos desarrollado una tecnología avanzada de monitoreo que analiza detalladamente las cuentas de Twitter. Nos enfocamos en los tweets relacionados con el conflicto israelí-palestino, empleando un algoritmo sofisticado que evalúa si una cuenta expresa contenido antisemita y, de ser así, determina el grado de dicha expresión.',
        parrafo3:
          'Este enfoque nos permite destacar no solo los comentarios explícitamente ofensivos sino también aquellos que pueden ser sutiles o no tan evidentes, aportando a un entendimiento más completo del antisemitismo en las redes sociales.',
        parrafo4:
          'Publicamos los resultados de nuestras auditorías en nuestra cuenta de Twitter, ',
        parrafo4b:
          ', donde las cuentas auditadas se muestran junto con sus respectivos resultados. Esta práctica resalta nuestro compromiso con la transparencia y ofrece una herramienta valiosa para aquellos interesados en las dinámicas del discurso en línea.',
        parrafo5:
          'En AntisemiteScore.org, nos esforzamos por crear un mundo donde el respeto y la comprensión mutua superen al odio y la intolerancia. A través de nuestra plataforma, buscamos aumentar la conciencia sobre el antisemitismo y empoderar a los usuarios para que reconozcan y confronten el discurso de odio.',
        parrafo6:
          'Nuestro proyecto no es solo una solución tecnológica; es un llamado a la acción para un cambio positivo en la forma en que nos comunicamos y entendemos las diferencias culturales y personales.',
        disclaimer: 'Descargo de responsabilidad',
        aboutus: 'Sobre Nosotros',
        howitworks: 'Como Funciona',
        iomessage: 'Comenzando',
        iomessage2: 'Obteniendo y Guardando',
        iomessage3: 'Filtrando contenido relacionado',
        iomessage4: 'Limpiando texto',
        iomessage5: 'Procesando',
        iomessage6: 'Publicando en @antisemitescore',
        suspendido:
          'Nuestra cuenta en X (Ex-Twitter) ha sido temporalmente suspendida, posiblemente debido a un malentendido con las políticas de la plataforma. En https://antisemitescore.org, continuamos nuestro compromiso de aumentar la conciencia sobre el antisemitismo y combatir el discurso de odio. Estamos en comunicación con X para aclarar y resolver esta situación, asegurando que nuestras actividades estén alineadas con sus directrices. Nuestra misión persiste a través de otros canales mientras trabajamos para reanudar nuestra actividad en X. Valoramos su apoyo y comprensión durante este tiempo.',
      },
    },
    pt: {
      translation: {
        botonAnalizar: 'Analisar',
        placeholderPerfil: 'URL do perfil do Twitter / @usuário...',
        history: 'Histórico de Pesquisas',
        topSearched: 'Principais pesquisas',
        relatedTweets: 'Tweets Relacionados',
        score: 'Pontuação de Antissemitismo:',
        noScore: 'Pontuação não disponível',
        toast1: 'Você deve fornecer uma conta do Twitter ou nome de usuário',
        toast2: 'Não encontramos nenhuma publicação relacionada ao evento',
        toast3:
          'Atualmente, não há publicações falando sobre o ataque ou o conflito',
        toast4: 'Usuário não existe',
        toast5: 'Algo deu errado',
        toast6: 'Erro desconhecido. Tente depois.',
        nothing: 'Nada por aqui',
        step1:
          'Insira uma conta do Twitter, e nossa tecnologia lerá todas as publicações e atribuirá uma pontuação de antissemitismo.',
        eliminarhistorial:
          'Você tem certeza de que deseja excluir este usuário?',
        explicacion:
          'A nossa tecnologia oferece um serviço avançado de monitoramento de contas do Twitter, realizando uma análise exaustiva das publicações. Ao focar nos tweets relacionados ao conflito israelense-palestino, juntamente com um algoritmo sofisticado, determinamos se a conta expressa conteúdo antissemita e em que grau.',
        explicacion2:
          'As contas auditadas serão publicadas juntamente com seus resultados em nossa conta no Twitter: @antisemitescore',
        share: 'Compartilhar no Twitter',
        parrafo1:
          'AntisemiteScore.org representa um esforço coletivo para enfrentar o antissemitismo no âmbito digital. Nossa equipe diversificada, composta por desenvolvedores de várias nacionalidades, ideologias e origens, une-se sob a convicção comum de promover a tolerância e os direitos humanos.',
        parrafo2:
          'Nossa missão é identificar e desafiar o discurso antissemita, especialmente nas redes sociais. Para alcançar isso, desenvolvemos uma tecnologia avançada de monitoramento que analisa detalhadamente as contas do Twitter. Nos concentramos nos tweets relacionados ao conflito israelense-palestino, empregando um algoritmo sofisticado que avalia se uma conta expressa conteúdo antissemita e, em caso afirmativo, determina a extensão dessa expressão.',
        parrafo3:
          'Esta abordagem nos permite destacar não apenas comentários explicitamente ofensivos, mas também aqueles que podem ser sutis ou menos óbvios, contribuindo para uma compreensão mais completa do antissemitismo nas redes sociais.',
        parrafo4:
          'Publicamos os resultados de nossas auditorias em nossa conta do Twitter, ',
        parrafo4b:
          ', onde as contas auditadas são exibidas juntamente com seus respectivos resultados. Esta prática sublinha nosso compromisso com a transparência e oferece uma ferramenta valiosa para aqueles interessados nas dinâmicas do discurso online.',
        parrafo5:
          'No AntisemiteScore.org, nos esforçamos para criar um mundo onde o respeito e o entendimento mútuo superem o ódio e a intolerância. Por meio de nossa plataforma, buscamos aumentar a consciência sobre o antissemitismo e capacitar os usuários a reconhecer e confrontar o discurso de ódio.',
        parrafo6:
          'Nosso projeto não é apenas uma solução tecnológica; é um chamado à ação para uma mudança positiva na forma como nos comunicamos e entendemos as diferenças culturais e pessoais.',
        disclaimer: 'Isenção de Responsabilidade',
        aboutus: 'Sobre nós',
        howitworks: 'Como Funciona',
        iomessage: 'Começando',
        iomessage2: 'Buscando & Salvando',
        iomessage3: 'Filtrando conteúdo relacionado',
        iomessage4: 'Limpando texto',
        iomessage5: 'Processando',
        iomessage6: 'Publicando em @antisemitescore',
        suspendido:
          'Nossa conta no X (anteriormente Twitter) foi temporariamente suspensa, possivelmente devido a um mal-entendido com as políticas da plataforma. Em https://antisemitescore.org, continuamos nosso compromisso de aumentar a conscientização sobre o antissemitismo e combater o discurso de ódio. Estamos em comunicação com o X para esclarecer e resolver esta situação, garantindo que nossas atividades estejam alinhadas com suas diretrizes. Nossa missão persiste por outros canais enquanto trabalhamos para retomar nossa atividade no X. Valorizamos seu apoio e compreensão durante este tempo.',
      },
    },
  },
  lng: 'en', // idioma por defecto
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
