import React, { useRef, useEffect, useState } from 'react';
import {
  Box,
  Skeleton,
  Text,
  VStack,
  Stack,
  Link,
  HStack,
} from '@chakra-ui/react';
import { FaTwitterSquare } from 'react-icons/fa';

import CustomCard from './CustomCard';
import './style/scroll.css';

const TweetPreview = ({ data, targetDuration, onFinish }) => {
  const scrollContainer = useRef(null);
  const [duration, setDuration] = useState(3000 * targetDuration);
  
  useEffect(() => {
    const element = scrollContainer.current;
    if (element) {
      let startTime;
      const start = element.scrollTop;
      const end = element.scrollHeight - element.clientHeight;
      const change = end - start;

      const animateScroll = timestamp => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const scrollValue = start + (change * progress) / duration;
        element.scrollTop = scrollValue;
        if (progress < duration) {
          requestAnimationFrame(animateScroll);
        } else {
          setTermino(true);
          onFinish();
        }
      };

      requestAnimationFrame(animateScroll);
    }
  }, []);

  // const handleOpenTwitt = id => {
  //   window.href = `https://twitter.com/${localStorage.perfil}/status/${id}`;
  // };

  const [termino, setTermino] = useState(false);
  return (
    <Box
      height={!termino ? '400px' : 'auto'}
      padding="15px"
      marginTop="23px"
      overflowY={'hidden'}
      ref={scrollContainer}
      className="div-scroll"
    >
      <VStack spacing={4}>
        {data.map((item, index) =>
          item === 0 && !termino ? (
            <Stack key={index} w={'100%'}>
              <CustomCard>
                <Skeleton height="20px">
                  <Text>
                    <small>Tweet not related..</small>
                  </Text>
                </Skeleton>
              </CustomCard>
            </Stack>
          ) : (
            item !== 0 && (
              <CustomCard key={index}>
                <Box>
                  <Text key={index}>{item.text}</Text>
                </Box>
                <Box marginTop="13px" textAlign="right">
                  <Link
                    href={`https://twitter.com/${localStorage.perfil}/status/${item.id}`}
                    isExternal
                  >
                    <HStack justifyContent="flex-end">
                      <Box display="flex" alignItems="flex-end">
                        <small>
                          <FaTwitterSquare size="15px" />
                        </small>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <small>ver mas...</small>
                      </Box>
                    </HStack>
                  </Link>
                </Box>
              </CustomCard>
            )
          )
        )}
      </VStack>
    </Box>
  );
};

export default TweetPreview;
