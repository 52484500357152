import { Box, Image, Text } from '@chakra-ui/react'
import React from 'react'
import logo from '../../assets/logo/logo.svg';

export default function LogoSubtitle() {
  return (
    <>
        <Box>
          <Image src={logo} w={'200'} mt={'50px'}  />
        </Box>
        <Text m={'50px 10px'} align={'center'}>Ingrese una cuenta de Twitter y nuestra tecnologia leerá todo lo escrito y otorgará un score de Antisemitismo</Text>
    </>
  )
}
