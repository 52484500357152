import {
  Box,
  VStack,
  Grid,
  GridItem,
  SimpleGrid,
  IconButton,
  Text,
  Link,
  HStack,
  Avatar,
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { FiShare } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import './core/translations/i18n';
import apiService from './core/network/ApiService';
import { formatNumber, getColor, shareContent } from './core/utils/Format';


function HistorialItem({ usuario, onDelete }) {
  const { t } = useTranslation();
  const [usuarios, setUsuarios] = useState([]);
  const [perfilpic, setPerfilpic] = useState();
  const [followers, setFollowers] = useState();
  const [following, setFollowing] = useState();
  const [searchedCount, setSearchedCount] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint as needed

    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener('change', handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange);
    };
  }, []);

  useEffect(() => {
    const datosLocalStorage = localStorage.getItem('puntuaciones');
    if (datosLocalStorage) {
      setUsuarios(JSON.parse(datosLocalStorage));
    }

    apiService
      .get(`userdata/${usuario.usuario}`, {
   
      })
      .then(res => {
        const data = res.data;
        setPerfilpic(data.profile_image_url);
        if (data.public_metrics.followers_count !== null) {
          setFollowers(formatNumber(data.public_metrics.followers_count));
          setFollowing(formatNumber(data.public_metrics.following_count));
        } else {
          setFollowers(0);
          setFollowing(0);
        }

        if(data.searched_count){
          setSearchedCount(data.searched_count);
        }

      });
  }, []);

  const eliminarUsuario = nombreUsuario => {
    if (window.confirm(`${nombreUsuario}: ` + t('eliminarhistorial'))) {
      const nuevosUsuarios = usuarios.filter(
        user => user.usuario !== nombreUsuario
      );
      setUsuarios(nuevosUsuarios);
      onDelete();
      // Actualizar localStorage
      localStorage.setItem('puntuaciones', JSON.stringify(nuevosUsuarios));
    }
  };
  const shareHandler = perfil => {
    shareContent(perfil);
  };


  return (
    <GridItem w="100%" height="auto">
      <Grid
        w="100%"
        h="auto"
        templateColumns="min-content auto min-content"
        borderLeft={`5px solid ${getColor(usuario.puntuacion.toString())}`}
        borderRight={`1px solid #eee`}
        borderTop={`1px solid #eee`}
      >
        <Box pl={4} display="flex" alignItems="center" height="100%">
          <Avatar
            alignItems="center"
            border={`5px solid ${getColor(usuario.puntuacion.toString())}`}
            name="Dan Abrahmov"
            src={perfilpic}
          />
        </Box>

        <Box padding={'17px 0 0 17px'}>
          <VStack>
            <Box w={'100%'}>
              <Text
                fontSize="13px"
                alignItems="left"
                fontWeight={'500'}
              >
                <Link
                  href={'https://twitter.com/' + usuario.usuario}
                  isExternal
                >
                  @{usuario.usuario}
                </Link>
              </Text>
            </Box>
            <Box w={'100%'}>
              <SimpleGrid
                columns={{ sm: 1, md: 2 }}
                paddingTop="17px"
                paddingLeft="9px"
              >
                <Box alignItems="left">
                  <Text fontSize={'11px'}>
                    <b> {following}</b> Siguiendo
                  </Text>
                </Box>
                <Box>
                  <Text fontSize={'11px'}>
                    <b> {followers} </b>Seguidores
                  </Text>
                </Box>

                <Box>
                  <Text fontSize={'11px'}>
                    <b> {searchedCount} </b>Busquedas
                  </Text>
                </Box>

              </SimpleGrid>
            </Box>
            <Box w={'100%'}>
              <Text fontSize="13px"></Text>
            </Box>
            <Box w={'100%'}>
              <HStack>
               { isMobile? <IconButton
                  aria-label="Icono 2"
                  style={{ background: 'transparent' }}
                  icon={<FiShare />}
                  onClick={() => shareHandler(usuario.usuario)}
                />: <br/>}
                <IconButton
                  aria-label="Icono 2"
                  style={{ background: 'transparent' }}
                  icon={<MdDelete />}
                  onClick={() => eliminarUsuario(usuario.usuario)}
                />
              </HStack>
            </Box>
          </VStack>
        </Box>
        <Box
          marginLeft="5px"
          display="flex"
          justifyContent="center"
          alignItems="baseline"
          pr={3}
        >
          <Text
            alignSelf="center"
            fontSize="xl"
            color={getColor(usuario.puntuacion.toString())}
            fontWeight="700"
          >
            {usuario.puntuacion}
          </Text>
        </Box>
      </Grid>
    </GridItem>
  );
}

export default HistorialItem;
