import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  Divider,
} from '@chakra-ui/react';

const DisclaimerText = () => {
  return (
    <Box p={5}>
      <Heading as="h2" size="lg" fontWeight="bold" mb={4}>
        Disclaimer of Lack of Responsibility for Results
      </Heading>
      <Divider my={4} />
      <Text mb={4}>
        This disclaimer is intended to clarify the limitations of responsibility
        related to any results, outcomes, or consequences that may arise from
        the use of a product, service, information, or advice provided by
        Antisemitescore.org or any of its representatives. By using our
        products, services, or engaging with any content or guidance offered,
        you acknowledge and agree to the following:
      </Text>

      <UnorderedList spacing={3}>
        <ListItem>
          <Text as="b">No Guarantees:</Text> Antisemitescore.org does not
          guarantee or warrant any specific results, achievements, or outcomes
          as a result of using our products, services, information, or advice.
          Any success or failure experienced is highly dependent on various
          factors, many of which are beyond our control.
        </ListItem>
        <ListItem>
          <Text as="b">Individual Circumstances:</Text> Results can vary greatly
          depending on individual circumstances, skills, knowledge, effort, and
          resources. We do not take responsibility for the specific results you
          may achieve, as these are influenced by your unique situation.
        </ListItem>
        <ListItem>
          <Text as="b">No Professional Advice:</Text> Antisemitescore.org may
          provide information, guidance, or recommendations, but these should
          not be considered as professional, legal, financial, or medical
          advice. Always consult with a qualified professional for specific,
          personalized guidance in these areas.
        </ListItem>
        <ListItem>
          <Text as="b">Third-Party Products and Services:</Text> We may provide
          information or references to third-party products, services, or
          resources. Antisemitescore.org is not responsible for the quality,
          reliability, or outcomes associated with these third-party offerings.
        </ListItem>
        <ListItem>
          <Text as="b">Unforeseen Events:</Text> Circumstances beyond our
          control, including market changes, economic fluctuations, regulatory
          changes, or other unforeseen events, may impact the results of any
          venture or endeavor. We are not responsible for such external factors.
        </ListItem>
        <ListItem>
          <Text as="b">No Liability:</Text> Antisemitescore.org shall not be
          held liable for any direct or indirect damages, losses, or adverse
          consequences resulting from the use or reliance on our products,
          services, information, or advice.
        </ListItem>
        <ListItem>
          <Text as="b">Updates and Changes:</Text> We may update, modify, or
          discontinue our products, services, or content at any time. We are not
          responsible for any inconvenience or adverse effects resulting from
          such changes.
        </ListItem>
        <ListItem>
          <Text as="b">User Responsibility:</Text> Users are responsible for
          their choices, actions, and decisions. It is essential to conduct due
          diligence, research, and seek independent advice when making
          significant decisions or taking actions based on the information or
          guidance provided by Antisemitescore.org.
        </ListItem>
      </UnorderedList>

      <Text mt={6}>
        By using our products, services, or engaging with our content, you
        acknowledge that you have read and understood this disclaimer. You agree
        that Antisemitescore.org and its representatives are not responsible for
        any results or consequences that may arise from such usage. If you do
        not agree with this disclaimer, please refrain from using our products,
        services, or content.
      </Text>
      <Text mt={4}>
        This disclaimer is subject to change and may be updated without prior
        notice. It is your responsibility to review this disclaimer periodically
        for any updates or modifications.
      </Text>
    </Box>
  );
};

export default DisclaimerText;
