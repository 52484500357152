import React, { useEffect } from 'react';
import {
  ChakraProvider,
  useDisclosure,
} from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import Search from './Search';
import theme from './theme';
import ReactGA from 'react-ga';
import SocketService from './SocketService';
import './core/translations/i18n';

function generarIDUnico() {
  // Combina la fecha actual, rendimiento del tiempo y un número aleatorio
  return (
    'id_' +
    Date.now().toString(36) +
    '_' +
    Math.random().toString(36).substr(2, 9)
  );
}

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  useEffect(() => {
    onOpen();
    const idUnico = localStorage.getItem('socketid') || generarIDUnico();
    localStorage.setItem('socketid', idUnico);
    SocketService.init();
    SocketService.emitRegistration(idUnico);
    ReactGA.initialize('G-BLKHCH9VSW');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return () => {
      SocketService.disconnect();
    };
  }, []);
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/:prompt" element={<Search />} />
        <Route path="/" element={<Search />} />
      </Routes>
    </ChakraProvider>
  );
}

export default App;
