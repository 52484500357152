import React, { useState, useEffect } from 'react';
import { Image, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const getDefaultLanguage = () => {
  const browserLang = navigator.language.slice(0, 2);
  const supportedLanguages = ['es', 'pt', 'en'];
  return supportedLanguages.includes(browserLang) ? browserLang : 'en';
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(getDefaultLanguage());

  useEffect(() => {
    const savedLanguage =
      localStorage.getItem('language') || getDefaultLanguage();
    setCurrentLanguage(savedLanguage);
    i18n.changeLanguage(savedLanguage);
  }, [i18n]);

  const changeLanguage = languageCode => {
    setCurrentLanguage(languageCode);
    i18n.changeLanguage(languageCode);
    localStorage.setItem('language', languageCode);
  };

  const languages = [
    { code: 'es', name: 'Español' },
    { code: 'pt', name: 'Português' },
    { code: 'en', name: 'English' },
  ];

  const availableLanguages = languages.filter(
    lang => lang.code !== currentLanguage
  );

  const flagUrl = code => `https://flagcdn.com/h20/${code}.png`;

  return (
    <HStack>
      {availableLanguages.map(lang => (
        <Image
          key={lang.code}
          style={{ border: '3px #AAA solid' }}
          src={flagUrl(lang.code === 'en' ? 'us' : lang.code)}
          alt={`Flag of ${lang.name}`}
          borderRadius="full"
          boxSize="29px"
          m={2}
          cursor="pointer"
          onClick={() => changeLanguage(lang.code)}
        />
      ))}
    </HStack>
  );
};

export default LanguageSelector;
