import { Skeleton,  Stack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import apiService from '../../core/network/ApiService';
import UserItem from '../components/UserItem.js';



export default function TopSearched() {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  useEffect(() => {
    setLoading(true)
    setUsers([])
    apiService
      .get(`topSearchedUsers`, {
      })
      .then(res => {
        const data = res.data;
        setUsers(data)
        setLoading(false)
      });
      
  }, []);
  return (
    loading ? <Stack>
    <Skeleton height='40px' />
    <Skeleton height='40px' />
    <Skeleton height='40px' />
  </Stack> : users.map(user =>  
    // <GridItem w="100%" height="auto" key={user.userId}>
    //   <Grid
    //     w="100%"
    //     h="auto"
    //     templateColumns="min-content auto min-content"
    //     // borderLeft={`5px solid ${color}`}
    //     borderLeft={getColor(user['score']) +'  solid 6px'}
    //     borderRight={`1px solid #eee`}
    //     borderTop={`1px solid #eee`}
    //   >
    //     <Box pl={4} display="flex" alignItems="center" height="100%">
    //       <Avatar
    //         alignItems="center"
    //         // border={`5px solid ${color}`}
    //         name={user['userName']}
    //         src={user['profile_pic']}
    //       />
    //     </Box>

    //     <Box padding={'17px 0 0 17px'} >
    //       <VStack >
    //         <Box w={'100%'}>
    //           <Text
    //             fontSize="13px"
    //             alignItems="left"
    //             fontWeight={'500'}
    //           >
    //             <Link
    //               href={'https://twitter.com/' + user['userName']}
    //               isExternal
    //             >
    //               @{user['userName']}
    //             </Link>
    //           </Text>

    //         </Box>
    //         <Box>
    //               <Text  fontSize={'11px'} color={'#A7A7A7'}> 
    //                 {user['searched_count']} Busquedas
    //               </Text>
    //         </Box>
    //         <Box w={'100%'}>
    //           <SimpleGrid
    //             columns={{ sm: 1, md: 2 }}
    //             paddingTop="17px"
    //             paddingLeft="9px"
    //           >
    //             <Box alignItems="left">
    //               <Text fontSize={'11px'}>
    //                 {/* <b> {following}</b> Siguiendo */}
    //               </Text>
    //             </Box>
    //             {/* <Box>
    //               <Text fontSize={'11px'}>
    //                 <b> {formatNumber(user['followers'])} </b>Seguidores
    //               </Text>
    //             </Box> */}

               

    //           </SimpleGrid>
    //         </Box>
    //         <Box w={'100%'}>
    //           <Text fontSize="13px"></Text>
    //         </Box>
    //         <Box w={'100%'}>
    //           <HStack>
    //             {/* <IconButton
    //               aria-label="Icono 2"
    //               style={{ background: 'transparent' }}
    //               icon={<FiShare />}
    //               onClick={() => shareHandler(usuario.usuario)}
    //             /> */}
    //           </HStack>
    //         </Box>
    //       </VStack>
    //     </Box>
    //     <Box
    //       marginLeft="5px"
    //       display="flex"
    //       justifyContent="center"
    //       alignItems="baseline"
    //       pr={3}
    //     >
    //       <Text
    //         alignSelf="center"
    //         fontSize="xl"
    //         color={getColor(user['score'])}
    //         fontWeight="700"
    //       >
    //         {user['score']}
    //       </Text>
    //     </Box>
    //   </Grid>
    // </GridItem>
       <UserItem key={user.userId} count={user['searched_count']} name={user['userName']} profilePicture={user['profile_pic']} score={user['score']} />
    )
  )
}
