import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  Box,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import '../../../../core/translations/i18n';

const ModalHowItWorks = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Box maxHeight="md" overflowY="auto" className="div-scroll">
            <Text>{t('explicacion')}</Text>
            <Text>{t('explicacion2')}</Text>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button
            color="white"
            backgroundColor="black"
            borderRadius="0"
            _hover={{ color: 'black', background: 'gray' }}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalHowItWorks;
