import React, { useEffect, useState } from 'react'
import { Avatar, Box, Grid, GridItem, Image, Text, Link,  } from '@chakra-ui/react'
import { getColor, shareContent } from '../../core/utils/Format'
import shareIcon from '../../assets/icons/share.svg';

export default function UserCard({count, profilePicture, score, name}) {

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia('(max-width: 767px)'); // Adjust the breakpoint as needed

    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener('change', handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener('change', handleMobileChange);
    };
  }, []);
  return (
    <GridItem w="100%" height="auto" >
          <Grid
        w="100%"
        h="auto"
        templateColumns="min-content auto min-content"
        borderLeft={getColor(score) + ' solid 6px'}
        padding={'16px 0px'} 
      >
         <Box pl={4} display="flex" alignItems="center" height="100%">
          <Avatar
            alignItems="center"
            border={`2px solid ${getColor(score)}`}
            name="Dan Abrahmov"
            src={profilePicture}
          />
        </Box>
        {/* TEXT SECTION */}
         <Box pl={4} display="flex" alignItems="start" flexDirection={'column'} height="100%">
          <Text mt={'6px'} fontSize={'19px'} fontWeight={'400'}>
          <Link
                  href={'https://twitter.com/' + name}
                  isExternal
                >
                  @{name}
            </Link>
          </Text>
          <Text mt={'6px'} color={'#A7A7A7'} fontSize={'14px'} fontWeight={'400'} letterSpacing={'-0.15px'}>
                  {count} veces buscado
          </Text>
          
       { isMobile ?   <Image onClick={()=> shareContent(name)} src={shareIcon} w={'15px'} h={'15px'}  mt={'12px'}/>: <br></br>}
        </Box>
        {/* SCORE SECTION */}
        <Box pl={4} display="flex" alignItems="start" flexDirection={'column'} height="100%">
          <Text  color={'#BE123C'} fontSize={'20px'} fontWeight={'700'} letterSpacing={'-0.3px'}>
                  {score} 
          </Text>
        </Box>
        
        

      </Grid>
    </GridItem>
  )
}
