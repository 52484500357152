import React, { useState } from 'react'
import Footer from './Footer'
import TestButton from '../TestButton'
import ModalAboutUs from './about_us/ModalAboutUs'
import { t } from 'i18next'
import {
    Text,
  } from '@chakra-ui/react';
import ModalHowItWorks from './how_it_works/ModalHowItWorks'
import ModalDisclaimer from './disclaimer/ModalDisclaimer'
import ModalApiToken from './api_token/ModalApiToken'

export default function AppFooter() {
    const [modal, setModal] = useState(0)

    const handleClose = () => {
        setModal(0)
    }

    const handleOpen = (e) => {
        setModal(e)
    }

  return (
    <Footer>
    <Text onClick={()=> handleOpen(1)} style={{ cursor: 'pointer' }}>
      <small>{t('aboutus')}</small>
    </Text>
    <Text onClick={()=> handleOpen(2)} style={{ cursor: 'pointer' }}>
      <small>{t('disclaimer')}</small>
    </Text>
    <Text onClick={()=> handleOpen(3)} style={{ cursor: 'pointer' }}>
      <small>{t('howitworks')}</small>
    </Text>
    <Text onClick={()=> handleOpen(3)} style={{ cursor: 'pointer' }}>
      <small>{t('howitworks')}</small>
    </Text>
    <Text onClick={()=> handleOpen(4)} style={{ cursor: 'pointer' }}>
      <small>{t('API Docs')}</small>
    </Text>
   
    <ModalAboutUs isOpen={modal === 1} onClose={handleClose} /> 
    <ModalDisclaimer isOpen={modal === 2} onClose={handleClose} />
    <ModalHowItWorks isOpen={modal === 3 } onClose={handleClose} />
    <ModalApiToken isOpen={modal === 4 } onClose={handleClose} />
   <TestButton/>
  </Footer>
  )
}
